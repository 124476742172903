.main-content {
  padding-top: map-get($top-bar-height, mobile);
  @include media-breakpoint-up(lg) {
    padding-top: map-get($top-bar-height, desktop);
  }
}

.actions {
  margin: 1rem 0;
  text-align: center;
  border-radius: 0;

  button {
    margin-right: 0.7rem;
    border-radius: 0px;
    background-color: $primary-color;
    border-color: $primary-color;
    color: white;
    font-weight: $weight-bold;
    border-radius: 10px;

    transition: all .05s ease-in-out;
    outline-style: solid;
    outline-width: 0px;
    outline-color: $primary-color;
    position: relative;

    &::after {
      position: absolute;
      width: 103%;
      height: 104%;
      content: '';
      background-color: transparent;
      top: -1px;
      left: -2px;
      border: 4px solid $primary-color;
      border-radius: 10px;
    }

    &:hover {
      background-color: $primary-color;
      border-color: $primary-color;
      outline-width: 2px;
    }

  }
}