.col-20p {
  flex: 0 0 20%;
  max-width: 20%;
}

@include media-breakpoint-up(sm) {
  .col-sm-20p {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

//line up
.multiColumnPanel {
  max-width: 960px;
  margin: auto;

  .container {
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(xl) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}

section.common-footer .multiColumnPanel {
  max-width: none;

  >div.container {
    margin-left: 0;
    margin-right: 0;
  }

  .contentPanel .container {
    max-width: 550px;

    @include media-breakpoint-down(xs) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .contactForm {
    max-width: 440px;
    margin: 0;
  }

  .form-control {
    height: calc(1.2em + 0.75rem + 2px);
    border-radius: 0.5rem;
    background-color: white
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .mb-2 {
    margin-bottom: 0px !important;
  }

  textarea {
    height: 100px;
  }

  .actions {
    margin: 0px;
    text-align: left;
    margin-top: 0.5rem;

    @include media-breakpoint-down(xs) {
      text-align: center;
    }

    button {
      border-radius: 0.5rem;
    }
  }

  .col-sm-4 .htmlPanel,
  .col-sm-5 .htmlPanel {
    //border-left: 2px solid red;
    //border-left: 3px solid $color-green;
    padding-left: 1.5rem;

    @include media-breakpoint-down(xs) {
      margin-top: 2rem;
    }

    p img {
      width: 86%;
    }
  }

  section.contentPanel {
    background-color: transparent;
    padding-top: 0px;
    padding-bottom: 0px;

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      margin-top: 0px;
      margin-bottom: 1.6rem;
    }
  }

  .bottom-info-text {
    @include media-breakpoint-down(xs) {
      text-align: center;
    }
  }

  // a {
  //   color: $font-color;
  // }

}